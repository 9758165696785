import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/app/docs/node_modules/@uchiru/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The Link component styles anchor tags with default blue styling and hover text decoration. `}<inlineCode parentName="p">{`Link`}</inlineCode>{` is used for destinations, or moving from one page to another.`}</p>
    <p>{`In special cases where you'd like a `}<inlineCode parentName="p">{`<button>`}</inlineCode>{` styled like a `}<inlineCode parentName="p">{`Link`}</inlineCode>{`, use `}<inlineCode parentName="p">{`<Link as='button'>`}</inlineCode>{`. Make sure to provide a click handler with `}<inlineCode parentName="p">{`onClick`}</inlineCode>{`.`}</p>
    <p><strong parentName="p">{`Important:`}</strong>{` When using the `}<inlineCode parentName="p">{`as`}</inlineCode>{` prop, be sure to always render an accessible element type, like `}<inlineCode parentName="p">{`a`}</inlineCode>{`, `}<inlineCode parentName="p">{`button`}</inlineCode>{`, `}<inlineCode parentName="p">{`input`}</inlineCode>{`, or `}<inlineCode parentName="p">{`summary`}</inlineCode>{`.`}</p>
    <h2>{`Default example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<Link mb={1} href="https://github.com">
  Link
</Link>
`}</code></pre>
    <h2>{`System props`}</h2>
    <p>{`Link components get `}<inlineCode parentName="p">{`COMMON`}</inlineCode>{` and `}<inlineCode parentName="p">{`TYPOGRAPHY`}</inlineCode>{` system props. Read our `}<a parentName="p" {...{
        "href": "/about/system-props"
      }}>{`System Props`}</a>{` doc page for a full list of available props.`}</p>
    <h2>{`Component props`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`href`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`URL to be used for the Link`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`muted`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Uses light gray for Link color, and blue on hover`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`underline`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Adds underline to the Link`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`as`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`'a'`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Can be 'a', 'button', 'input', or 'summary'`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      